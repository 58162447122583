import * as React from "react"
import Layout from "../components/layout"
import Helmet from 'react-helmet'
import Articles from "../components/articles"
import useIntersection from '../components/useIntersection'; // import the custom hook
import classNames from 'classnames';

const Section = ({  id, className, children }) => {
  const [ref, isIntersecting] = useIntersection({
    root: null, // Use the viewport as the root
    rootMargin: '0px',
    threshold: 0.25 // Trigger when 50% of the element is in view
  });

  return (
    <section
      id={id}
      ref={ref}
      className={classNames(className, { 'not-in-view': !isIntersecting })}
    >
      {children}
    </section>
  );
};


const ArticleSpeed = () => {
  return (
  <>
<Helmet
    bodyAttributes={{
        class: 'article-page whitemain'
    }}
/>
  <Layout pageTitle="ArticleSpeed">
    <section id="main">
      <div id="article-intro">
        <div className="left-col">
          <h1><span className="revealblue">Website Speed and Performance Optimisation is Important</span></h1>
          <p><span className="revealblack">DEV INSIGHTS | 28 MAR 2017</span></p>
        </div>
        <div className="right-col picture">
            <img src={'img/single/single-news.jpg'} width="840" alt="Website Speed Performance" />
        </div>
      </div>
    </section>

    <Section id="article-content">
      <div id="inner-content">

        <h2>How fast does your website load? Website speed is one of Google’s factors in rankings, and here at Tobstar we help to make sites load faster.</h2>
<p>Website speed and performance is an important factor to consider when promoting your website, because user experience and usabilities can depend on the speed of website loading. You may be surprised that an increase in website speed will actually have an effect on your sales and may further engage your users, increase return of visitors and improve conversations.</p>
<p><a href="https://developers.google.com/speed/pagespeed/insights/" target="_blank" rel="noreferrer">Google’s algorithm also includes page speed as a factor to be measured</a>. This is one of the many factors that affect on Google ranking, but it does not significantly contribute to Google rankings. Google also include a factor for mobile pages to be measured and whether they are mobile friendly and whether this is fast and responsive.</p>
<p>There is an additional level of coding, optimising and server configuring to improve your website speed, starting with:</p>
<ul>
	<li>Avoid redirects</li>
	<li>Optimise your website’s images</li>
	<li>Minify all Javascript, CSS and HTML files (resources)</li>
	<li>Reduce number of resources requests and remove query strings on the resources</li>
	<li>Enable compression and leverage browser caching on the server</li>
	<li>Eliminate render-blocking Javascript &amp; CSS in above-the-fold content</li>
	<li>WordPress plugins to include&nbsp;<a href="https://en-au.wordpress.org/plugins/wp-super-cache/" target="_blank" rel="noreferrer">WP Super Cache</a>&nbsp;and&nbsp;<a href="https://en-au.wordpress.org/plugins/autoptimize/" target="_blank" rel="noreferrer">Autoptimize</a></li><li>Reduce number of plugins or remove unused plugins</li>
	<li>Remove unused resources</li>
	</ul>
<p>Overall the speed performance can contribute to improve or weaken your conversation rate, every second you saved on load time can gain the user or customer’s confidence and trust in your website and this will help them speak positively about your website. If your website is loading slowly and costing your conversations, contact us to discuss further optimising your website speed and performance.</p>
<p><b>Useful links:</b></p>
	<p><a href="https://www.crazyegg.com/blog/speed-up-your-website/">10 Ways to Speed Up Your Website – and Improve Conversion by 7%</a></p>
	<p><iframe className="wp-embedded-content" sandbox="allow-scripts" security="restricted" src="https://www.crazyegg.com/blog/speed-up-your-website/embed/#?secret=akcTkYXU3s" data-secret="akcTkYXU3s" width="600" height="281" title="“10 Ways to Speed Up Your Website – and Improve Conversion by 7%” — The Daily Egg" frameborder="0" marginwidth="0" marginheight="0" scrolling="no"></iframe></p>
	<p><a href="https://www.keycdn.com/blog/google-pagespeed-insights-wordpress/" target="_blank" rel="noreferrer">https://www.keycdn.com/blog/google-pagespeed-insights-wordpress/</a></p>
	<p><b>Speed test tools:</b></p>
	<p><a href="https://developers.google.com/speed/pagespeed/insights/" target="_blank" rel="noreferrer">https://developers.google.com/speed/pagespeed/insights/</a></p>
	<p><a href="https://gtmetrix.com/" target="_blank" rel="noreferrer">https://gtmetrix.com/</a></p>


      </div>
    </Section>
<Articles></Articles>
</Layout>

  </>
  )
}

export default ArticleSpeed


export const Head = () => (
  <>
    <title>Website Speed and Performance Optimisation is Important - Tobstar</title>
    <meta name="description" content="Website speed is an important factor to consider when promoting your website, because user experience and usabilities can depend on the speed of website." />
  </>
)